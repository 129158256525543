import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import StyledBoard from "components/StyledBoard";
import axios from "axios";
import { Chess, Move } from "chess.js";
import NextIcon from "images/NextMoveIcon.svg";
import PrevIcon from "images/PreviousMoveIcon.svg";
import FirstIcon from "images/FirstMoveIcon.svg";
import LastIcon from "images/LastMoveIcon.svg";
import RightArrowIcon from "images/RightArrowIcon.svg";
import useSound from "use-sound";
import moveSfx from "sounds/moveSound.mp3";
import captureSfx from "sounds/captureSound.mp3";
import checkSfx from "sounds/checkSound.mp3";
import castleSfx from "sounds/castleSound.mp3";

const GuessTheElo = () => {
	const [playerElo, setPlayerElo] = useState<number>(0);
	const [moveList, setMoveList] = useState<Move[]>([]);
	const [currentPosition, setCurrentPosition] = useState<string>("");
	const [positionIndex, setPositionIndex] = useState<number>(-1);
	const [boardSize, setBoardSize] = useState<number>(0);
	const [guess, setGuess] = useState<string>("");
	const [submitted, setSubmitted] = useState<boolean>(false);
	const [reactionEmoji, setReactionEmoji] = useState<string>("");

	const [playMoveSound] = useSound(moveSfx);
	const [playCaptureSound] = useSound(captureSfx)
	const [playCheckSound] = useSound(checkSfx);
	const [playCastleSound] = useSound(castleSfx)

	useEffect(() => {
		if (moveList.length)
			window.addEventListener("keyup", (event: any) => {
				event.stopImmediatePropagation();
				switch (true) {
					case event.key === "ArrowLeft" && !event.shiftKey:
						setPositionIndex((positionIndex) =>
							Math.max(positionIndex - 1, 0)
						);
						break;
					case event.key === "ArrowRight" && !event.shiftKey:
						setPositionIndex((positionIndex) =>
							Math.min(positionIndex + 1, moveList.length)
						);
						break;
					case event.key === "ArrowLeft" && event.shiftKey:
						setPositionIndex(0);
						break;
					case event.key === "ArrowRight" && event.shiftKey:
						setPositionIndex(moveList.length);
						break;
				}
			});
	}, [moveList]);

	useEffect(() => {
		const getRandomGame = async () => {
			try {
				const response = await axios.get(
					"https://us-central1-chess-574e4.cloudfunctions.net/randomGTEGame"
				);
				if (response.data.move_list) {
					let chess = new Chess();
					chess.load_pgn(response.data.move_list, { sloppy: true });
					setPlayerElo(response.data.white_elo);
					setMoveList(chess.history({ verbose: true }));
				}
			} catch (error) {
				console.error(error);
			}
		};
		getRandomGame();

		const handleBoardSize = () => {
			let calculatedWidth;
			if (window.innerWidth > 959) {
				calculatedWidth = window.innerWidth * 0.666 - 100;
			} else {
				calculatedWidth = window.innerWidth * 0.9;
			}
			setBoardSize(Math.min(600, calculatedWidth));
		};

		window.addEventListener("resize", handleBoardSize);
		handleBoardSize();

		return () => window.removeEventListener("resize", handleBoardSize);
	}, []);

	useEffect(() => {
		if (moveList) {
			const tempGame = new Chess();
			if (positionIndex < 0) {
				setCurrentPosition(tempGame.fen())
			} else {
				let moves = moveList.slice(0, positionIndex + 1);
				moves.forEach((move: Move) => {
					tempGame.move(move);
				});
				setCurrentPosition(tempGame.fen());
			}
		}
	}, [positionIndex, moveList]);

	const changePosition = (index: number) => {
		handleSound(index);
		setPositionIndex(index)
	}

	const handleSound = (index: number) => {
		//If user is clicking backwards, play the sound for the move that's reversing
		let moveToCheck = Math.max(index, positionIndex);
		if (moveToCheck < 0) {
			playMoveSound();
			setPositionIndex(moveToCheck)
		} else {
			const captureRegEx = /[x]/g;
			const castleRegEx = /[-]/g;
			const checkRegEx = /[+]/g;
			const isCapture = captureRegEx.test(moveList[moveToCheck].san)
			const isCastle = castleRegEx.test(moveList[moveToCheck].san)
			const isCheck = checkRegEx.test(moveList[moveToCheck].san)
			if (isCapture) {
				playCaptureSound();
			} else if (isCastle) {
				playCastleSound();
			} else if (isCheck) {
				playCheckSound();
			} else {
				playMoveSound();
			}
		}
	}

	const handleSubmit = (e: any) => {
		e.preventDefault();
		setSubmitted(true);
		let guessOffset = Math.abs(playerElo - parseInt(guess));
		if (guessOffset <= 100) {
			setReactionEmoji("🤩");
		} else if (guessOffset > 100 && guessOffset <= 500) {
			setReactionEmoji("👀");
		} else {
			setReactionEmoji("💩");
		}
	};

	return (
		<GameContainer>
			<StyledBoard boardWidth={boardSize} position={currentPosition} />
			<InputContainer>
				<StyledHeading>Guess the ELO</StyledHeading>
				<span>
					<StyledButton onClick={() => changePosition(-1)} disabled={positionIndex < 0}>
						<img
							src={FirstIcon}
							alt="Click to see the first move"
						/>
					</StyledButton>
					<StyledButton
						onClick={() => changePosition(Math.max(positionIndex - 1, -1))
						}
						disabled={positionIndex === -1}
					>
						<img
							src={PrevIcon}
							alt="Click to see the previous move"
						/>
					</StyledButton>
					<StyledButton
						onClick={() => changePosition(Math.min(positionIndex + 1, moveList.length - 1))
						}
						disabled={positionIndex === moveList.length - 1}
					>
						<img src={NextIcon} alt="Click to see the next move" />
					</StyledButton>
					<StyledButton
						onClick={() => changePosition(moveList.length - 1)}
						disabled={positionIndex === moveList.length - 1}
					>
						<img src={LastIcon} alt="Click to see the last move" />
					</StyledButton>
				</span>
				{submitted ? (
					<StyledForm>
						<CorrectEloText
							offset={Math.abs(playerElo - parseInt(guess))}
						>
							Correct ELO: {playerElo}
						</CorrectEloText>
						<EloOffsetText
							offset={Math.abs(playerElo - parseInt(guess))}
						>
							{reactionEmoji}{" "}
							{Math.abs(playerElo - parseInt(guess))} ELO Off{" "}
							{reactionEmoji}
						</EloOffsetText>
						<BlueButton>
							Next Game
							<img
								src={RightArrowIcon}
								alt="Right Arrow Icon"
							/>
						</BlueButton>
					</StyledForm>
				) : (
					<StyledForm onSubmit={(e) => handleSubmit(e)}>
						<StyledLabel>Enter white’s ELO</StyledLabel>
						<StyledInput
							type="number"
							value={guess}
							onChange={(e) => setGuess(e.target.value)}
							placeholder="Ex. Enter white’s ELO"
						></StyledInput>
						<BlueButton disabled={!guess.length}>Submit</BlueButton>
					</StyledForm>
				)}
			</InputContainer>
		</GameContainer>
	);
};

const GameContainer = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	gap: 50px;
	padding: 0px 50px 50px;
	box-sizing: border-box;
	background-color: #faf9f4;
	@media (max-width: 1200px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	max-width: 700px;
	@media (max-width: 1200px) {
		max-width: 450px;
		gap: 15px;
	}
`;

const StyledHeading = styled.p`
	font-family: "Reuben";
	font-weight: 400;
	font-size: 64px;
	color: #4f4f4f;
	margin: 0 0 50px 0;
	text-align: center;
	@media (max-width: 1200px) {
		display: none;
	}
`;

const StyledButton = styled.button`
	border: none;
	background: none;
	margin: 0 10px 50px;
	padding: 0;
	&:hover {
		cursor: pointer;
	}
	&:disabled {
		opacity: 0.5;
	}
	@media (max-width: 1200px) {
		margin: 0 5px;
		img {
			width: 54px;
		}
	}
`;

const CorrectEloText = styled.p<{ offset: number }>`
	font-family: "Montserrat-Alternates Medium";
	font-weight: 500;
	font-size: 36px;
	line-height: 36px;
	color: #5a9e92;
	color: ${(props) =>
		props.offset > 100 && props.offset <= 500 ? "#E5BD2D" : "#CF4B33"};
	margin-bottom: 10px;
	@media (max-width: 1200px) {
		font-size: 24px;
		line-height: 36px;
	}
`;

const EloOffsetText = styled.p<{ offset: number }>`
	font-family: "Reuben Extended";
	font-weight: 400;
	font-size: 36px;
	line-height: 54px;
	color: #5a9e92;
	color: ${(props) =>
		props.offset > 100 && props.offset <= 500 ? "#E5BD2D" : "#CF4B33"};
	text-align: center;
	text-transform: uppercase;
	margin: 0 0 80px 0;
	@media (max-width: 1200px) {
		font-size: 20px;
		line-height: 30px;
		margin: 0 0 30px 0;
	}
`;

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const StyledLabel = styled.label`
	display: inline-block;
	font-family: "Montserrat-Alternates Medium";
	font-weight: 500;
	font-size: 24px;
	color: #4f4f4f;
	margin-bottom: 15px;
`;

const StyledInput = styled.input`
	background: #faf9f4;
	border: 3px solid #4f4f4f;
	border-radius: 8px;
	height: 53px;
	width: calc(100% - 60px);
	margin-bottom: 50px;

	font-family: "Montserrat-Alternates SemiBold";
	font-weight: 600;
	font-size: 16px;
	text-align: left;
	color: #4f4f4f;
	padding: 8px;
	box-sizing: border-box;
	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

const BlueButton = styled.button`
	display: flex;
	align-items: center;
	background: #b6c5db;
	border-radius: 70px;
	border: none;
	padding: 11.5px 66.5px;
	font-family: "Montserrat-Alternates SemiBold";
	font-weight: 600;
	font-size: 14px;
	color: #4f4f4f;
	text-transform: uppercase;
	&:hover {
		cursor: pointer;
	}
	img {
		margin-left: 5px;
	}
	&:disabled {
		cursor: none;
		opacity: 0.5;
	}
`;

export default GuessTheElo;
