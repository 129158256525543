import { createGlobalStyle } from "styled-components";
import Reuben from "fonts/Reuben.otf";
import ReubenExtended from "fonts/Reuben-Extended.otf";
import MontserratAlternatesMedium from "fonts/MontserratAlternates-Medium.woff";
import MontserratAlternatesSemiBold from "fonts/MontserratAlternates-SemiBold.woff";

export default createGlobalStyle`
	@font-face {
		font-family: 'Reuben';
		src: local('Reuben'), local('Reuben'),
		url(${Reuben}) format('opentype');
	}

	@font-face {
		font-family: 'Reuben Extended';
		src: local('Reuben Extended'), local('Reuben Extended'),
		url(${ReubenExtended}) format('opentype');
	}

	@font-face {
		font-family: 'Montserrat-Alternates Medium';
		src: local('Montserrat-Alternates Medium'), local('Montserrat-Alternates Medium'),
		url(${MontserratAlternatesMedium}) format('opentype');
	}

	@font-face {
		font-family: 'Montserrat-Alternates SemiBold';
		src: local('Montserrat-Alternates SemiBold'), local('Montserrat-Alternates SemiBold'),
		url(${MontserratAlternatesSemiBold}) format('opentype');
	}
`;
