import React from "react";
import FontStyles from "fontStyles";
import "./App.css";
import GlobalNav from "components/GlobalNav";
import GuessTheElo from "components/GuessTheElo";

function App() {
	return (
		<div className="App">
			<FontStyles />
			<GlobalNav />
			<GuessTheElo />
		</div>
	);
}

export default App;
