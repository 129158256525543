import React from "react";
import styled from "styled-components/macro";
import { Chessboard } from "react-chessboard";
import {
	BishopBlack,
	KingBlack,
	KnightBlack,
	PawnBlack,
	QueenBlack,
	RookBlack,
	BishopWhite,
	KingWhite,
	KnightWhite,
	PawnWhite,
	QueenWhite,
	RookWhite,
} from "images/pieces/index";
import { Chess } from "chess.js";

interface Props {
	boardWidth: number;
	position: string;
}

export default function StyledBoard({ boardWidth, position }: Props) {
	// If still waiting for async fetch to return position, render the board
	// with a temporary start position
	const chess = new Chess();
	let startPosition = chess.fen();
	let currentPosition = position.length ? position : startPosition;

	const customPieces = {
		wP: () => <StyledChessPiece src={PawnWhite} alt="White Pawn Piece" />,
		wN: () => (
			<StyledChessPiece src={KnightWhite} alt="White Knight Piece" />
		),
		wB: () => (
			<StyledChessPiece src={BishopWhite} alt="White Bishop Piece" />
		),
		wR: () => <StyledChessPiece src={RookWhite} alt="White Rook Piece" />,
		wQ: () => <StyledChessPiece src={QueenWhite} alt="White Queen Piece" />,
		wK: () => <StyledChessPiece src={KingWhite} alt="White King Piece" />,
		bP: () => <StyledChessPiece src={PawnBlack} alt="Black Pawn Piece" />,
		bN: () => (
			<StyledChessPiece src={KnightBlack} alt="Black Knight Piece" />
		),
		bB: () => (
			<StyledChessPiece src={BishopBlack} alt="Black Bishop Piece" />
		),
		bR: () => <StyledChessPiece src={RookBlack} alt="Black Rook Piece" />,
		bQ: () => <StyledChessPiece src={QueenBlack} alt="Black Queen Piece" />,
		bK: () => <StyledChessPiece src={KingBlack} alt="Black King Piece" />,
	};

	return (
		<ChessboardWrapper size={boardWidth}>
			<Chessboard
				animationDuration={200}
				arePiecesDraggable={false}
				boardOrientation="white"
				position={currentPosition}
				customBoardStyle={{ border: "5px solid #000000" }}
				boardWidth={boardWidth}
				customDarkSquareStyle={{ backgroundColor: "#B6C5DB" }}
				customLightSquareStyle={{ backgroundColor: "#FAF9F4" }}
				customPieces={customPieces}
			/>
		</ChessboardWrapper>
	);
}

const StyledChessPiece = styled.img`
	height: 80%;
	position: relative;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
`;

/** 
 * react-chessboard sets 'touch-action: none;' on each piece, making it 
 * impossible to scroll on mobile if a user tries to tap-and-drag on a piece.
 * Adding this transparent overlay so the user is actually tapping on a
 * div with standard touch events
 */
const ChessboardWrapper = styled.div<{ size: number }>`
	::after {
		content: '';
		position: absolute;
		display: block;
		width: ${props => props.size * 1.05}px;
		height: ${props => props.size * 1.05}px;
		top: 0;
    	z-index: 10;
	}
`;