import React from "react";
import styled from "styled-components/macro";
import PawnIcon from "images/PawnIcon.svg";

const GlobalNav = () => {
	return (
		<StyledNav>
			<LogoWrapper>
				<LogoTitle>Chessla</LogoTitle>
				<LogoIcon src={PawnIcon} />
			</LogoWrapper>
			<NavLink href="https://www.buymeacoffee.com/chesslaio">
				Buy us a coffee
			</NavLink>
		</StyledNav>
	);
};

const StyledNav = styled.div`
	height: 80px;
	width: 100%;
	margin-bottom: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 51px;
	box-sizing: border-box;
	@media (max-width: 1200px) {
		justify-content: center;
		margin-bottom: 0px;
	}
`;

const LogoWrapper = styled.span`
	position: relative;
	display: flex;
	align-items: baseline;
`;

const LogoTitle = styled.p`
	font-family: "Reuben Extended";
	font-size: 48px;
	color: #4f4f4f;
	margin: 0;
`;

const LogoIcon = styled.img`
	position: relative;
	bottom: -1px;
`;

const NavLink = styled.a`
	font-family: "Montserrat-Alternates SemiBold";
	font-weight: 600;
	font-size: 18px;
	line-height: 27px;
	color: #4f4f4f;
	text-decoration: none;
	@media (max-width: 1200px) {
		display: none;
	}
`;

export default GlobalNav;
